<template>
  <div class="container">
    <!-- <van-nav-bar left-arrow title="编辑资料" right-text="保存"  @click-right="saveUser" @click-left="$router.back()"></van-nav-bar> -->
    <van-nav-bar title="打开KM小程序" left-arrow @click-left="goBack()" />
    <div v-if="isWeixin || isMobile">
      <div style="text-align: center; margin: 100px 2px">
        <h2>正在打开 中酿KM小程序...</h2>
      </div>
      <div class="btn-box">
        <van-button
          type="info"
          size="large"
          :loading="isloading" 
          :disabled="isloading"
          round
          block
          @click="openmini"
          v-if="openlink != ''||isloading" 
          loading-text="加载中..."
          >点击重试</van-button
        >
        <van-button type="danger" size="large" block v-if="openlink == ''&& !isloading"
          >获取配置信息异常,请联系管理员</van-button
        >
      </div>
    </div>
    <div v-if="isDesktop">
      <div style="text-align: center; margin: 100px 2px">
        <h2>请在手机打开网页链接,PC端不支持打开</h2>
      </div>
      <div style="text-align: center; margin: 20px 2px">
        <h2></h2>
      </div>
    </div>
  </div>
</template>
  
<script>
import { GetWXopenlink } from "@/api/wxuser";
import { Toast } from "vant";

export default {
  name: "opnemini-km",
  data() {
    return {
      openlink: "",
      isloading:true,
      isWeixin: false,
      isMobile: false,
      isDesktop: false,
    };
  },
  created() {
    this.init();
    this.GetLink();
  },
  methods: {
    goBack() {
      if (this.$route.query.back) {
        this.$router.back();
      } else {
        this.$router.replace({ path: "/" });
      }
    },

    init() {
      var ua = navigator.userAgent.toLowerCase();
      var isWXWork = ua.match(/wxwork/i) == "wxwork";
      this.isWeixin =
        !isWXWork && ua.match(/micromessenger/i) == "micromessenger";
      this.isMobile = false;
      this.isDesktop = false;
      if (
        navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|IEMobile)/i
        )
      ) {
        this.isMobile = true;
      } else {
        this.isDesktop = true;
      }
    },

    async GetLink() {
      try {
       let para= this.$route.query.para;
       if(para==undefined){
          para="";
       }
        const data = await GetWXopenlink(para);
        if (data.data.success) {
          this.openlink = data.data.data;
          if(this.isWXWork||this.isMobile){
            this.openmini();
          }
        } else {
          Toast(data.data.errorMessage);
        }
      } catch (error) {
        console.log(error);
      }
      this.isloading=false;
    },

    openmini() {
      window.location.href = this.openlink;
    },
  },
};
</script>
  
  <style lang="less" scoped>
.btn-box {
  padding: 20px;
}
</style>
  